<template>
  <div class="relative cursor-pointer" :style="data.userAvatarStyle" >
    <nuxt-link :to="'/personalHomePage?userId=' + userId" target="_blank">
      <img
          v-if="props.src"
          :src="props.src"
          :style="data.userAvatarStyle"
          class="object-cover rounded-full"
          @error="
        (e) => {
          e.target.src = defaultAvatar;
        }
      "
      />
      <img v-else :src="defaultAvatar" :style="data.userAvatarStyle" alt="" />
    </nuxt-link>
    <div class="identification absolute bottom-0 right-0" v-if="props.showIdentification">
      <img :style="data.propIdentificationSize" src="@/assets/icons/identification_designer_18.svg" v-if="props.userType === '设计师'" />
      <img :style="data.propIdentificationSize" src="@/assets/icons/identification_roommeasurer.svg" v-if="props.userType === '量房员'" />
      <img :style="data.propIdentificationSize" src="@/assets/icons/identification_decorationcp.svg" v-if="props.userType === '装修公司'" />
      <img :style="data.propIdentificationSize" src="@/assets/icons/identification_designdepartment_18.svg" v-if="props.userType === '设计机构'" />
      <img :style="data.propIdentificationSize" src="@/assets/icons/identification_serviceprovider.svg" v-if="props.userType === '品牌商' || props.userType === '经销商'" />
    </div>
  </div>
</template>

<script setup>
// 用户头像、身份组件
import { reactive } from "vue";
import defaultAvatar from "@/assets/icons/pc_home_mhead_icon.svg";
const router = useRouter();

const props = defineProps({
  // 路径
  src: {
    type: String,
    default: defaultAvatar,
  },
  // 尺寸
  size: {
    type: Number,
    default: 44,
  },
  // 是否显示角标
  showIdentification: {
    type: Boolean,
    default: true,
  },
  // 角标大小
  identificationSize: {
    type: Number,
    default: 15,
  },
  //角色类别
  userType: {
    type: String,
    default: "",
  },
  // 用户id
  userId: {
    type: String,
  },
});

const data = reactive({
  userAvatarStyle: {},
  serverType: 1,
  propIdentificationSize: {},
});

data.userAvatarStyle = {
  width: props.size + "px",
  height: props.size + "px",
};
data.propIdentificationSize = {
  width: props.identificationSize + "px",
  height: props.identificationSize + "px",
};
</script>

<style lang="less" scoped></style>
